import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="ALTRspace" />
    
    
    <StaticImage
      src="../images/altarbox-logo.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Altarbox"
      style={{ marginBottom: `1.45rem` }}
    />

  <h1>ALTR: coming soon</h1>

  </Layout>
)

export default IndexPage
